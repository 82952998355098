<template>
    <v-app>
        <div class="">
            <div class="">
            <div class="container">
                <div class="row">
                <div class="col-md-12">
                </div>
                </div>
            </div>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            
                            <v-col cols="12" md="6">
                                <h4 class="mb-5 ml-2">Please enter your delivery address.</h4>
                                <v-form
                                    ref="form"
                                    @submit="order()"
                                    v-model="valid"
                                    :lazy-validation="lazy"
                                    class="pa-3"
                                >
                                    <v-textarea
                                    v-model="delivery_address"
                                    class="mt-5"
                                    solo
                                    :rules="[v => !!v || 'Address is required']"
                                    name="input-7-4"
                                    label="Example: House 1, Road 54/A, Gulshan 2, Dhaka"
                                    ></v-textarea>

                                    <h4 class="mb-5">Payment Method</h4>

                                    <v-radio-group
                                        :rules="[v => !!v || 'You must select one!']" 
                                        v-model="radios" 
                                        :mandatory="false"
                                    >
                                        <v-radio value="cash"></v-radio>
                                        <span class="position">Cash on Delivery</span>
                                        <v-radio  value="bkash"></v-radio>
                                        <span class="position">bKash on Delivery</span>
                                    </v-radio-group>
                                    

                                    <div class="my-2">
                                    <v-btn
                                        style="color: #fff; border-radius: 10px"
                                        color="#e54c2a" 
                                        :disabled="!valid"  
                                        large 
                                        @click="order()"
                                        :loading="loading"
                                    >
                                    Submit
                                    </v-btn>
                                </div>
                                </v-form>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-container>
            
            </div>
        </div>
        <notifications group="error" position="bottom left" />
        <!-- snackbar -->
            <v-snackbar v-model="snackbar">
                {{ text }}
                <v-btn color="pink" text  @click="snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        <!-- snackbar -->
    </v-app>
</template>

<script>
// import Breadcrumb from "@/components/common/BreadcrumbComponent";
import { mapGetters } from 'vuex';
import config from "@/config";
export default {
    name: 'PlaceOrder',
    components: {},
    data () {
        return {
            vat: config.VAT_RATE > 0 ? config.VAT_PERCENTAGE : 0,
            deliveryCharge: 60,
            radios: '',
            snackbar: false,
            text: '',
            valid: true,
            delivery_address: '',
            loading: false,
            lazy: false,
            products: [],
            allProduct: this.$store.state.cart,
            order_placed_by: ''
        }
    }, //end of data
    computed: {
      couponCode: function (){
        return this.$route.query.couponCode;
      },
      ...mapGetters({
        user:'user/GET_USER'
      }),
     
    },
    methods: {
        order() {
            let addressLength = this.delivery_address.trim().length
            if(addressLength == 0){
                // this.$notify({
                //     group: 'error',
                //     type: 'error',
                //     title: 'Do not submit with empty string.',
                //     // text: 'Your are logged in now'
                // });
                this.text = 'Invalid input.'
                this.snackbar = true
                return
            }
            
            this.loading = true;
            let loginInfo = localStorage.getItem("userToken");
            let user_id = localStorage.getItem("fk_uses_id");
            if (loginInfo === null) {
                this.loading = false;
                this.$router.push({ name: "signIn" });
            }
            else if( user_id === null)
            {
                this.loading = false;
                this.$router.push({ name: "signIn" });
            }
            else {
                //let cart = JSON.stringify(this.allProduct);
               
                let cart = this.allProduct;
                this.products = cart.map(i =>{
                        return {
                        fk_products_id: i.product.products_id,
                        quantity: i.count,
                        }
                })
                this.axios.post(`/orders/store?token=${loginInfo}`, 
                { 
                    products        : this.products,
                    coupon_code     : this.$route.query.couponCode,
                    payment_by      : this.radios,
                    delivery_address: this.delivery_address,
                    vat             : this.vat,
                    delivery_charge : this.deliveryCharge,
                    order_placed_by : this.order_placed_by,
                    fk_users_id     : localStorage.getItem("fk_uses_id")
                })
                .then(response => {
                // this.$store.commit('addTCart',0);
                    if(response.data.success == true){
                       cart = []
                        this.loading = false;
                        this.$refs.form.resetValidation();
                        this.$refs.form.reset();
                        localStorage.removeItem("cart");
                        this.$store.commit('cartValueClear')
                        this.$router.push({ name: "cart.alert" });
                    }else{
                        this.snackbar = true;
                        this.text = "Sorry, order cannot be completed. Please try again.";
                        this.loading = false;
                    }
                    
                });
        }
    },
    },
    created() {
        function getOS() {
            var userAgent = window.navigator.userAgent,
                platform = window.navigator.platform,
                macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                iosPlatforms = ['iPhone', 'iPad', 'iPod'],
                os = null;

            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'Mac OS';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'iOS';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'Windows';
            } else if (/Android/.test(userAgent)) {
                os = 'Android';
            } else if (!os && /Linux/.test(platform)) {
                os = 'Linux';
            }

            return os;
        }

        this.order_placed_by = 'Website (' + getOS() + ')';
        // this.delivery_address=this.user.address;
        // console.log(this.delivery_address)
        //alert(getOS());
    },

    mounted() {
    this.$store.dispatch('user/ACT_USER')
    
  },
  watch: {
    user: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.delivery_address = newValue.address;
        }
      }
    }
  },
}
</script>


<style scoped>
.breadcrumb-area:before {
    background-color: white !important;
    width: 0%;
    height: 0%;
}
    .position{
        position: relative;
        top: -31px;
        left: 31px;
    }
</style>